import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { footerHolder } from './Footer.module.scss';

export default function Footer() {
  return (
    <div className={footerHolder}>
      <div className="footerInner">
        <section id="about-joanna">
          <h3>About Joanna</h3>
          <p>
            As long as I can remember I have loved art and photography. Growing up as an only child I would spend hours drawing and moved onto
            photography when I left home. For years I worked as a physiotherapist just photographing my favourite things when I got the chance. I have
            created many scrapbooks over the years and was doing one on Richmond Park for my sons for when they left home. A friend commented that I
            should try and get it published. The rest as they say is history. A Year In The Life Of Richmond Park was published in 2000 and a whole
            new career opened up before me. I have subsequently had a further 7 books published. The most recent A Year In The Life Of Kingston Upon
            Thames being published in 2017. It has been a pleasure to combine my hobby with my work and I feel very privileged and lucky to have been
            able to make a living out of something that I love doing.
          </p>
          <StaticImage src="../images/footer-jo.jpg" alt="Joanna Jackson" />
        </section>
        <section id="order-a-print">
          <h3>Order a print</h3>
          <p>
            Any of the photos on this site (or from any of the books) are available to buy as prints directly from Joanna. If you’d like to order a
            print, simply email Joanna and let her know the print you require and she will email you back quickly with options and prices. All the
            limited edition prints are reproduced on heavy, very high quality archival paper and prices start at £40.
          </p>
        </section>
        <section id="contact">
          <div className="lrHolder">
            <div className="lhs">
              <h3>More from Joanna</h3>
              <ul>
                <li>
                  <a href="https://www.joannajackson.co.uk">Joanna Jackson</a>
                </li>
              </ul>
            </div>
            <div className="rhs">
              <h3>Contact</h3>
              <ul>
                <li>
                  <a href="mailto:joannalindsey@hotmail.com" title="Email Joanna">
                    joannalindsey@hotmail.com
                  </a>
                </li>
                <li>
                  <a href="tel:00447792515728" title="Call Joanna">
                    07792515728
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
