import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { layoutHolder } from './Layout.module.scss';
import Nav from './Nav';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/styles.scss';

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export default function Layout({ children }) {
  return (
    <div className={layoutHolder}>
      <Header />
      <Nav />
      <div className="content">
        <AnimatePresence exitBeforeEnter>
          <motion.main key={children?.props?.path} variants={variants} initial="pre" animate="visible" exit="post">
            {children}
          </motion.main>
        </AnimatePresence>
      </div>
      <Footer />
    </div>
  );
}
