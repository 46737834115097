import { Link } from 'gatsby';
import React from 'react';
import { navHolder } from './Nav.module.scss';

export default function Nav() {
  return (
    <div className={navHolder}>
      <h1>
        <span className="joannaJackson">Joanna Jackson</span> <span className="photography">Photography</span>
      </h1>
      <nav>
        <ul>
          <li>
            <Link to="/#ayitlo" title="Joanna Jackson Homepage">
              Home
            </Link>
          </li>
          <li>
            <Link to="/#galleries" title="Other Galleries">
              Other Galleries
            </Link>
          </li>
          <li>
            <Link to="/#about-joanna" title="About Joanna">
              About
            </Link>
          </li>
          <li>
            <Link to="/#order-a-print" title="Prints">
              Prints
            </Link>
          </li>
          <li>
            <Link to="/#contact" title="Contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
