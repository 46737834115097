import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { headerHolder } from './Header.module.scss';

export default function Header() {
  return (
    <div className={headerHolder}>
      <header>
        <StaticImage src="../images/header-stags.jpg" alt="Black and White photo of stags by Joanna Jackson" />
      </header>
    </div>
  );
}
